module.exports = {
  title: "Blog personnel de labaranlabs", // Required
  author: "Labaran Adam", // Required
  description:
    "Fell in love with a dumb machine that does exactly what I instruct it to do, when and how. Hoping our relationship makes the world a better place. || SOFTWARE ENGINEER",
  primaryColor: "#3498db", // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: "https://www.labaranlabs.com/",
    github: "https://github.com/labaran1",
    twitter: "https://twitter.com/labaranlabs",
    linkedin: "https://www.labaranlabs.com/",
  },
  pathPrefix: "/",
  siteUrl: "https://www.blog.labaranlabs.com",
};
